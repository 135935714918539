import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from './firebaseConfig';  // Import the auth instance
import { getFirestore, doc, setDoc } from "firebase/firestore"; // Import Firestore methods
import { useNavigate } from 'react-router-dom'; // Import for redirection with v6

const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [orderId, setOrderId] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate(); // useNavigate hook for redirection in v6

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      console.log('Signed up as:', user.email);

      // Initialize Firestore
      const db = getFirestore();

      // Set the initial user status in Firestore
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        orderId: orderId,
        status: "pending", // Set status to pending initially
        rejectionReason: "", // No rejection reason initially
        role: 'user',
        variant: ''
      });

      // Redirect to the approval status page
      navigate('/approval-status'); // Redirect using useNavigate

    } catch (err) {
      setError(err.message);
      console.error('Error during signup:', err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="signup-form">
      <h2>Sign Up</h2>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <div className="password-container">
          <input
            type={passwordVisible ? "text" : "password"}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <i
            className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"} toggle-password`}
            onClick={togglePasswordVisibility}
          ></i>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="order-id">Order ID</label>
        <input
          type="text"
          id="order-id"
          value={orderId}
          onChange={(e) => setOrderId(e.target.value)}
          placeholder="Order ID"
          required
        />
      </div>
      <button type="submit" className="sign-up-button">Sign Up</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
};

export default SignupForm;