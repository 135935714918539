import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ApprovalStatus from './components/ApprovalStatus';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import ForgotPassword from './ForgotPassword';
import LiveChat from './LiveChat'; // Import the LiveChat component
import AdminDashboard from './components/AdminDashboard'; // Import the AdminDashboard component

const App = () => {
  const [isLogin, setIsLogin] = useState(false);

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  useEffect(() => {
    document.title = isLogin ? "Nisarga Login" : "Nisarga Signup";
  }, [isLogin]);

  return (
    <Router> {/* Make sure this Router is wrapping all components */}
      {/* LiveChat component will be available across all pages */}
      <LiveChat />
      <Routes>
        <Route 
          path="/" 
          element={
            <div className="container">
              <div className="login-section">
                {isLogin ? <LoginForm /> : <SignupForm />}
              </div>
              <div className="welcome-section">
                {isLogin ? (
                  <>
                    <h2>Welcome to Nisarga</h2>
                    <p>Don't have an account?</p>
                    <button onClick={toggleForm} className="signup-button">
                      Sign Up
                    </button>
                  </>
                ) : (
                  <>
                    <h2>Welcome to Nisarga</h2>
                    <p>Already have an account?</p>
                    <button onClick={toggleForm} className="signup-button">
                      Sign In
                    </button>
                  </>
                )}
              </div>
            </div>
          } 
        />
        <Route path="/approval-status" element={<ApprovalStatus />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} /> {/* Add this route */}
      </Routes>
    </Router>
  );
};

export default App;
