// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD64Sx_FA4RRhpu0bSZJFcoIO87PAxNl9Y",
  authDomain: "nisarga-download-manager.firebaseapp.com",
  projectId: "nisarga-download-manager",
  storageBucket: "nisarga-download-manager.appspot.com",
  messagingSenderId: "1075614090859",
  appId: "1:1075614090859:web:75222bcce50e46ae5d532e",
  measurementId: "G-8R5LDBV8QC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
