import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVariants, setSelectedVariants] = useState({});
  const [editMode, setEditMode] = useState(null); // Track which user is being edited
  const [selectedStatus, setSelectedStatus] = useState({});
  const [activeTab, setActiveTab] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false); // Track sidebar collapse state
  const [error, setError] = useState(null); // State to store any error messages
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          setError("You are not logged in. Redirecting to login...");
          setTimeout(() => navigate('/'), 2000); // Redirect to login page after 2 seconds
          return;
        }

        // Fetch the current user's document to check if they are an admin
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const userData = userDoc.data();

        if (userData.role !== 'admin') {
          setError("You are not authorized to access the admin dashboard. Redirecting...");
          setTimeout(() => navigate('/'), 2000); // Redirect non-admin users to login page
          return;
        }

        // If admin, fetch the users collection
        const usersCollection = collection(db, 'users');
        const userSnapshot = await getDocs(usersCollection);
        const users = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(users);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  const handleVariantChange = (userId, variant) => {
    setSelectedVariants(prev => ({
      ...prev,
      [userId]: variant
    }));
  };

  const handleStatusChange = (userId, status) => {
    setSelectedStatus(prev => ({
      ...prev,
      [userId]: status
    }));
  };

  const handleEditClick = (userId) => {
    setEditMode(userId); // Set the user to edit mode
    setSelectedVariants(prev => ({
      ...prev,
      [userId]: users.find(user => user.id === userId).variant || ''
    }));
    setSelectedStatus(prev => ({
      ...prev,
      [userId]: users.find(user => user.id === userId).status || ''
    }));
  };

  const handleSaveClick = async (userId) => {
    const updatedVariant = selectedVariants[userId];
    const updatedStatus = selectedStatus[userId];

    if (!updatedVariant || !updatedStatus) {
      alert("Please select both a variant and status before saving.");
      return;
    }

    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { variant: updatedVariant, status: updatedStatus });
    
    setUsers(prev => prev.map(user => 
      user.id === userId ? { ...user, variant: updatedVariant, status: updatedStatus } : user
    ));
    setEditMode(null); // Exit edit mode after saving
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>; // Show a loading message while the users are being fetched
  }

  if (error) {
    return <p>{error}</p>; // Display the error message if any
  }

  const filteredUsers = users.filter(user => 
    (activeTab === 'all' || user.status === activeTab) &&
    (user.email.toLowerCase().includes(searchQuery.toLowerCase()) || user.orderId.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className={`admin-dashboard ${isCollapsed ? 'collapsed' : ''}`}>
      <aside className="dashboard-sidebar">
        <h1 className="sidebar-title">Admin Dashboard</h1>
        <ul>
          <li className={activeTab === 'all' ? 'active' : ''} onClick={() => setActiveTab('all')}>
            <i className="fas fa-users"></i> <span className="nav-text">All Users</span>
          </li>
          <li className={activeTab === 'pending' ? 'active' : ''} onClick={() => setActiveTab('pending')}>
            <i className="fas fa-hourglass-half"></i> <span className="nav-text">Pending Users</span>
          </li>
          <li className={activeTab === 'approved' ? 'active' : ''} onClick={() => setActiveTab('approved')}>
            <i className="fas fa-check"></i> <span className="nav-text">Approved Users</span>
          </li>
          <li className={activeTab === 'rejected' ? 'active' : ''} onClick={() => setActiveTab('rejected')}>
            <i className="fas fa-times"></i> <span className="nav-text">Rejected Users</span>
          </li>
        </ul>

        <button className="toggle-button" onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? '>' : '<'}
        </button>

        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">
            <i className="fas fa-sign-out-alt"></i> <span className="nav-text">Logout</span>
          </button>
        </div>
      </aside>

      <main className="dashboard-content">
        <div className="search-filter-bar">
          <input 
            type="text" 
            placeholder="Search by email or order ID" 
            value={searchQuery} 
            onChange={(e) => setSearchQuery(e.target.value)} 
          />
        </div>

        <div className="table-container">
          {filteredUsers.length === 0 ? (
            <p>No users with {activeTab === 'all' ? 'any' : activeTab} status.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Order ID</th>
                  <th>Variant</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map(user => (
                  <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>{user.orderId}</td>
                    <td>
                      {editMode === user.id ? (
                        <select
                          value={selectedVariants[user.id] || ''}
                          onChange={(e) => handleVariantChange(user.id, e.target.value)}
                        >
                          <option value="">Select Variant</option>
                          <option value="lite">Lite</option>
                          <option value="pro">Pro</option>
                          <option value="ultimate">Ultimate</option>
                        </select>
                      ) : (
                        user.variant || 'N/A'
                      )}
                    </td>
                    <td>
                      {editMode === user.id ? (
                        <select
                          value={selectedStatus[user.id] || ''}
                          onChange={(e) => handleStatusChange(user.id, e.target.value)}
                        >
                          <option value="">Select Status</option>
                          <option value="pending">Pending</option>
                          <option value="approved">Approved</option>
                          <option value="rejected">Rejected</option>
                        </select>
                      ) : (
                        user.status || 'N/A'
                      )}
                    </td>
                    <td>
                      {editMode === user.id ? (
                        <button onClick={() => handleSaveClick(user.id)}>Save</button>
                      ) : (
                        <button onClick={() => handleEditClick(user.id)}>Edit</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;
